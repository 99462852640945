import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import SitetitleSp from "@/ui/images/sitetitle_sp.png";
import { Footer } from "@/ui/components/panes/Footer/Footer";

const Copyright = styled.div`
  height: 70px;
  text-align: center;
  font-size: 0.8125rem;
  padding-top: 28px;
`;

const Wrapper = styled.div`
  background-color: #002664;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const PageTitle = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  display: block;
  width: 215px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  img {
    display: block;
    width: 215px;
  }
`;

const Main = styled.div`
  padding-bottom: 80px;

  h1 {
    width: 91.6030534351%;
    max-width: 700px;
    background-color: #4fb4ca;
    border-radius: 16px 16px 0px 0px;
    text-align: center;
    font-size: 1.125rem;
    color: #fff;
    padding: 12px 0;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  width: 91.6030534351%;
  max-width: 700px;
  position: relative;
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;
  padding: 37px 0 80px 0;
  margin-left: auto;
  margin-right: auto;
`;

const Section = styled.section`
  width: 83%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;

  h2 {
    border-bottom: solid 1px #002664;
    font-size: 1rem;
    line-height: 1em;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  h2:before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: #4fb4ca;
    border-radius: 500px;
    margin-right: 5px;
  }
`;

const BgBlue = styled.div`
  background-color: #f0f4fa;
  padding: 33px 26px;
`;

const Skyblue = styled.p`
  color: #4fb4ca;
  font-size: 0.875rem;
  line-height: 1.625rem;
  margin-bottom: 1em;
`;

const DotLine = styled.p`
  border-top: dashed 1px #002664;
  border-bottom: dashed 1px #002664;
  padding: 10px 0;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.625rem;
  margin-bottom: 1em;
`;

const Link = styled.a`
  color: #4fb4ca;
  word-break: break-all;
  font-size: 0.875rem;
  line-height: 1.625rem;

  &:visited {
    color: #4fb4ca;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    font-size: 0.875rem;
    line-height: 1.625rem;
  }
`;

const Button = styled.button`
  position: absolute;
  bottom: -22px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  width: 175px;
  border-radius: 500px;
  background-color: #4fb4ca;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-size: 1.125rem;
  padding: 13px 0;
`;

const Attention = styled.p`
  color: #cc0000;
  font-size: 0.875rem;
  line-height: 1.625rem;
  margin-bottom: 1em;
  text-align: center;
`;

const AlignLeftAttention = styled(Attention)`
  text-align: left;
`;

export const InformationCampaign = () => {
  const navigate = useNavigate();

  const goTopAndScroll = () => {
    window.scrollTo(0, 0);
    navigate("/");
  };

  return (
    <Wrapper>
      <PageTitle onClick={() => navigate("/")}>
        <img src={SitetitleSp} alt="あなただけに特別ご招待キャンペーン" />
      </PageTitle>
      <Main>
        <h1>キャンペーン概要</h1>
        <Content>
          <Section>
            <Attention>キャンペーン応募受付は終了しました</Attention>
            <h2>応募方法・条件</h2>
            <Text>
              ①応募URL
              （https://teleboat-bc.jp）にアクセスし、本キャンペーンサイト（スマートフォン）経由でキャンペーン応募期間内にテレボート新規会員登録し、応募期間中に開催されるレースで合計100円以上の舟券を購入。
              <br />
              ※テレボートで購入できる全レース・全賭式が対象。
            </Text>
            <Text>
              ②本キャンペーンサイトにて、テレボート加入者番号、カナ氏名、メールアドレスを登録。
            </Text>
          </Section>
          <Section>
            <h2>抽選</h2>
            <Text>
              毎週各回（毎週火曜日）の応募締切日までにご応募された方の中より、抽選のうえ、毎週100名様に現金2,000円をプレゼントいたします。
            </Text>
            <BgBlue>
              <Ul>
                <li>※応募は20歳以上の方に限らせていただきます。</li>
                <li>
                  ※すでにテレボート会員の方、2回目以降の応募はキャンペーンの対象外となります。
                </li>
                <li>※返還金額も購入金額に含みます。</li>
                <li>※キャンペーンの応募と舟券購入の順番は問いません。</li>
                <li>
                  ※応募時にテレボート加入者番号等を誤って入力した場合、ご応募が無効となる場合がございますので、ご注意ください。
                </li>
              </Ul>
            </BgBlue>
          </Section>

          <Section>
            <h2>キャンペーン応募期間</h2>
            <Text>
              2024年5月15日(水)～
              <br />
              2024年12月31日(火)23：59まで
            </Text>
            <Skyblue>
              ※5月21日(火)～12月31日(火)の間、毎週火曜日が応募締切日となります。
            </Skyblue>
            <AlignLeftAttention>
              キャンペーン応募受付は終了しました
            </AlignLeftAttention>
          </Section>

          <Section>
            <h2>舟券購入対象期間</h2>
            <Text>
              2024年5月15日(水)～
              <br />
              2024年12月31日(火)当日最終レースまで
            </Text>
            <AlignLeftAttention>対象期間は終了しました</AlignLeftAttention>
          </Section>

          <Section>
            <h2>賞金</h2>
            <Text>抽選で毎週100名様に現金2,000円</Text>
          </Section>

          <Section>
            <h2>当選発表</h2>
            <Text>
              ご当選者様のテレボートに登録されている銀行口座へのお振込みをもって代えさせていただきます。
              <br />
              現金2,000円は、テレボートに登録されている銀行口座にお振込みいたします。
              <br />
              振込名義は「トクベツゴショウタイキャンペーンジムキョク」となります。
            </Text>
          </Section>

          <Section>
            <h2>お問い合わせ先</h2>
            <Text>
              当キャンペーン関するお問い合わせにつきましては、メールでのお問い合わせのみとさせていただきます。
            </Text>
            <DotLine>
              <Link href="mailTo:ml-meishi@boatrace.jp">
                ml-meishi@boatrace.jp
              </Link>
            </DotLine>
            <Text>《開設期間：2024年5月15日(水)～2025年1月31日(金) 》</Text>
            <AlignLeftAttention>
              キャンペーン応募受付は終了しました
            </AlignLeftAttention>
            <Ul>
              <li>
                ※事務局は、土日祝日および年末年始《
                2024年12月28日(土)～2025年1月5日(日)
                》は休業とさせていただきます。予めご了承ください。
              </li>
              <li>
                ※お問い合わせ内容によりましては、お返事に数日かかる場合もございますので、予めご了承ください。
              </li>
            </Ul>
          </Section>

          <Section>
            <h2>注意事項</h2>
            <Text>
              「ご利用規約」及び「個人情報の取り扱いについて」をよくお読みいただき、同意の上、ご応募ください。
            </Text>
            <Ul>
              <li>
                ※メールのドメイン指定受信を行われている方は、
                <Underline>info@teleboat-bc.jp</Underline>
                からのメールを受信できるように設定してからご応募ください。
              </li>
              <li>
                ※@icloud.comのドメインでは、メールの送受信に不備が発生する可能性がありますので、他のドメインをご利用ください。
              </li>
              <li>
                ※ご登録内容の確認・変更はこちら
                <br />
                <Link
                  href="https://register.mbrace.or.jp/kyotei/member/henkologin"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://register.mbrace.or.jp/kyotei/member/henkologin
                </Link>
              </li>
            </Ul>
          </Section>
          <Button onClick={() => goTopAndScroll()}>戻る</Button>
        </Content>
      </Main>
      <Footer />
      <Copyright id="copyright">
        Copyright TELEBOAT All Rights Reserved.
      </Copyright>
    </Wrapper>
  );
};
