import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import FooterBgWebp from "@/ui/images/footer_bg_sp.webp";
import IconArrow from "@/ui/images/icon_arrow_circle.svg";
import IconMail from "@/ui/images/icon_mail.svg";

const Wrapper = styled.footer`
  background-image: url(${FooterBgWebp});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: auto;
  padding: 37px 0 35px 0;
`;

const Content = styled.div`
  display: block;
  width: 91.6030534351%;
  max-width: 700px;
  background-image: url(${IconArrow});
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center right 22px;
  background-color: #fff;
  border-radius: 16px;
  border: solid 2px #002664;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  padding: 14px 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 19px;
  color: #002664;
  cursor: pointer;
`;

const Contact = styled.div`
  position: relative;
`;

const ContactTitle = styled.h2`
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 225px;
  border-radius: 500px;
  background-color: #4fb4ca;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 0;
  padding: 14px 0;

  &:before {
    content: "";
    display: block;
    width: 22px;
    height: 20px;
    background-image: url(${IconMail});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
    margin-right: 7px;
  }
`;

const ContactContainer = styled.div`
  position: relative;
  top: 23px;
  width: 91.6030534351%;
  max-width: 700px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 55px 0 40px 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 47px;
`;

const Email = styled.div`
  width: 87%;
  border-bottom: dotted 2px #002664;
  padding-bottom: 26px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  a,
  a:visited {
    font-size: 1.125rem;
    color: #4fb4ca;
  }
`;

const Date = styled.p`
  font-size: 0.9375rem;
  line-height: 1.5rem;
`;

const Notice = styled.div`
  width: 85%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding-left: 1.5em;
    text-indent: -1.5em;
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }
`;

const Registration = styled.a`
  display: block;
  width: 91.6030534351%;
  max-width: 700px;
  background-color: #002664;
  border-radius: 16px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0;

  &:visited {
    color: #fff;
  }
`;

const Attention = styled.p`
  color: #cc0000;
  font-size: 0.9375rem;
  line-height: 1.5rem;
`;

export const Info = () => {
  const navigate = useNavigate();
  const handlePageChange = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <Wrapper>
      <Content onClick={() => handlePageChange("/information_campaign/")}>
        キャンペーン概要
      </Content>
      <Content onClick={() => handlePageChange("/information_privacy/")}>
        個人情報の取り扱いについて
      </Content>
      <Content onClick={() => handlePageChange("/information_rules/")}>
        ご利用規約
      </Content>
      <Contact>
        <ContactTitle>お問い合わせ先</ContactTitle>
        <ContactContainer>
          <Email>
            <a href="mailto:ml-meishi@boatrace.jp">ml-meishi@boatrace.jp</a>
          </Email>
          <Date>
            開設期間
            <br />
            2024年5月15日(水)～2025年1月31日(金)
          </Date>
          <Attention>キャンペーン応募受付は終了しました</Attention>
        </ContactContainer>
      </Contact>
      <Notice>
        <ul>
          <li>
            ※
            土日祝日および年末年始(2024年12月28日(土)～2025年1月5日(日))は休業とさせていただきます。予めご了承ください。
          </li>
          <li>
            ※
            お問い合わせ内容によりましては、お返事に数日かかる場合もございますので、予めご了承ください。
          </li>
        </ul>
      </Notice>
      <Registration
        href="https://register.mbrace.or.jp/kyotei/member/henkologin"
        target="_blank"
      >
        テレボートのご登録内容の
        <br />
        確認・変更はこちら
      </Registration>
    </Wrapper>
  );
};
